{
	"buttons": {
		"all_presets": "All packages",
		"clear_filters": "Clear filters",
		"download_diploma": "Download diploma",
		"select_topic": "Course categories",
		"presets": "Course packages",
		"search": "Search",
		"clear": "Clear",
		"signin": "Sign in",
		"start_playlist": "Start playlist",
		"open_prescription": "Open prescription",
		"close_popup": "Close popup",
		"open_course_page": "Open course page",
		"close": "Close",
		"will_learn": "You will learn how to…",
		"show_completed": "Show completed",
		"hide_completed": "Hide completed",
		"open_course": "Open course",
		"choose_course": "Choose course",
		"wishlist_all": "All saved courses",
		"all_courses": "All courses",
		"all_on_topic": "All on topic",
		"all_recommended_programms": "All recommended programms",
		"all_required_programms": "All required programms",
		"prescription_all": "All assigned programs",
		"show_completed": "Show all completed",
        "hide_completed": "Hide all completed",
		"assign_all": "Assign to all",
		"send": "Send",
        "cancel": "Cancel",
		"notify_all": "Remind everyone",
		"add_file": "+ Add file",
		"reset": "Reset",
		"collapse_menu": "Collapse menu",
		"open_menu": "Open menu",
		"show_more": "Show more",
		"show_less": "Show less",
		"apply": "Аpply"
	},
	"course_types": {
		"all": "All types",
		"audition": "Sales Simulation",
		"business_case": "Business Case",
		"competences": "Test",
		"dialog": "Dialogue Simulation",
		"educational_materials": "Educational material",
		"graduation_project": "Graduation project",
		"interview": "Interview",
		"long": "Video course",
		"longread": "Longread",
		"materials": "Materials",
		"presentation": "Presentation",
		"quiz": "Quiz",
		"short": "Mini-course",
		"summary": "Summary of the book",
		"test": "Test",
		"playground": "Practical task",
		"practical_task": "Practical task"
	},
	"deadlines": {
		"late": "Expired",
		"finished": "Completed",
		"current": "Left",
		"continue": "Continue"
	},
	"placeholders": {
		"courses_search_placeholder": "What do you want to learn today?",
		"your_name": "Your name",
		"phone": "Phone",
		"your_answer": "Your answer",
		"your_answer_homework": "Your answer"
	},
	"duration": {
		"h": " h.",
		"min": " min."
	},
	"messages": {
		"no_filters_message": "Nothing found with these filters. Try resetting them.",
		"mute_message": "Click to turn the sound on.",
		"no_diplomas_message":"You don't have any completed courses yet.",
		"start_your_learning_path": "Start your learning path here",
		"choose_description": "Choose any of the hundreds interactive courses and simulations",
		"no_training": "No training was planned.",
		"no_recommended_training": "Recommended training was not assigned.",
		"megafon_1": "No training was planned as part of the 360 assessment development.",
		"megafon_2": "You have access to common programs from the library",
		"recommended_megafon_1": "You are not assigned any mandatory training.",
		"recommended_megafon_2": "You have access to common programs from the library",
		"no_wished_plan": "Nothing has been added to favorites.",
		"term_and_privacy": "By registering I hereby acknowledge that I have read the Eduson <a href=\"https://downloads.eduson.tv/pdf/eduson-privacy.pdf\" target=\"_blank\">Terms of Use</a> and EDUSON LLC may collect, use, transfer and information for the following purposes: <ul class=\"privacy-list\"><li>To generate reports and analyses after my completion of EDUSON LLC content and other services;</li><li>To provide educational information related to EDUSON LLC content and services. </li></ul>",
		"mega_term_and_privacy": "By registering I hereby acknowledge that I have read the Eduson <a href=\" https://downloads.eduson.tv/materials/files/000/005/732/original/Term_mega.pdf\" target=\"_blank\">Terms of Use</a> and EDUSON LLC may collect, use, transfer and information for the following purposes: <ul class=\"privacy-list\"><li>To generate reports and analyses after my completion of EDUSON LLC content and other services;</li><li>To provide educational information related to EDUSON LLC content and services. </li></ul>",
		"select_file": "Click or drag the file to the upload area",
		"answer_received": "Your answer has been received for review. Wait for the curator's response",
		"homework_accepted": "Homework accepted!",
		"homework_attempt_error": "Your answer has not been accepted. Try again",
		"end_time": "Until the end of the trial period"
	},
	"days": "no days | 1 day | {n} days",
	"minutes": "0 minutes | {n} minute | {n} minutes",
	"hours": "0 hours | {n} hour | {n} hours",
	"seconds": "0 secundes | {n} secund | {n} secundes",
	"today": "Today",
	"left": "left",
	"in": "in",
	"assignment": "Assignment",
	"filters": "Filters",
	"similar_courses": "Similar courses",
	"with_diploma": "With diploma",
	"learning_plan": "Learning plan",
	"learning_plan_completed_to": "Learning plan completeness",
	"recommended_programms": "Recommended programms",
	"megafon_plan": "Required courses",
	"megafon_plan_completed_to": "Required courses completeness",
	"recommended_megafon_plan": "Recommended courses for the 360 Assessment Development Program",
	"presets": "Course packages",
	"have_an_account": "Already have an account?",
	"progress": "Progress",
	"timecodes": "Timecodes",
	"where_to_begin": "Where to start...",
	"wish_courses": "Saved courses",
	"full_only": "Only in full version",
	"started": "Started",
	"sales_courses": {
        "cold_call": {
			"title": "Cold Calling Simulator",
			"description": "<p class='sales-type-description'>Sales reps listen to dozens of pre-recorded sales conversations and choose the best ways to pass the gatekeeper, to handle objections, and to close the sale.</p> <p class='sales-type-description'>When they meet an angry customer or hear the “call me later” objection in real situations, they are now prepared to react professionally and will remember what to say even in the toughest conversation.</p>"
		},
        "video_rehearsal": {
			"title": "Video Role-play Tool to Rehearse",
			"description": "<p class='sales-type-description'>Practice makes perfect. Record a series of videos with typical customer questions.</p> <p class='sales-type-description'>Then analyze the sales reps' video answers and provide feedback. Role-playing allows the student to train in a safe environment until the needed negotiating skills become second-nature and the rep is ready to face the customer.</p>"
		},
        "speech_recognition": {
			"title": "Speech Recognition Training Tool",
			"description": "<p class='sales-type-description'>Most sales reps suffer from “sales amnesia”.</p> <p class='sales-type-description'>Tell them something on Monday, and they forget it by Friday. It is curable now. Our training courses make salespeople repeat each phrase aloud a dozen times while training, again and again, until they get it word-perfect.</p> <p class='sales-type-description'>Eduson’s speech recognition technology won't accept incorrect answers.</p>"
		},
        "title": "Sales Training",
        "sub_title": "155 interactive training tools for sales reps and call center agents"
	},
	"used_license": "Licenses used",
	"active_till": "Active till",
	"contact_soon": "We will contact you soon",
	"employees_without_appointments": "Employees without assignments",
	"avg_count_assignments": "You have {avg_yours} assignments per employee, the average for your industry is {avg_total}",
	"overdue_appointments": "Assignments overdue",
	"notification_send": "Reminders are sent.",
	"check_them": "You can remind again",
	"sending_notification": "Sending notifications",
	"going_to_send": "You're going to send",
	"notifications": "0 notification | {n} notifications | {n} notifications | {n} notifications",
	"license_popup": {
		"title": "License acquiring application",
		"submit": "Send request",
		"message": "Our manager will contact you to discuss additional licenses for your employees.",
		"invite": "Invite",
		"purchase_licenses": "Purchase licenses"
	},
	"homework": "Homework",
	"homework_files": "Homework files",
	"curators_response": "Curator's response",
	"welcome_partners_title": "Leading companies trust Eduson.tv to train their employees",
	"welcome_why_eduson_title": "Training managers and company employees are satisfied with working with Eduson",
	"types_of_courses": "Types of courses",
	"subjects": "Subjects",
	"sorting": {
		"new_first": "New ones first",
		"sorting_AZ": "Alphabetically from A to Z",
		"sorting_ZA": "Alphabetically from Z to A",
		"popular": "Popular"
	},
	"found": "Found",
	"hide_completed_courses": "Hide completed courses",
	"show_wished_courses": "Show wished courses"
}
